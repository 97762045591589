import React from 'react'

import NotFound from '~/components/pages/404'
import SEO from '~/utils/seo'
import { PageProps } from 'gatsby'

export default function NotFoundPage({ location }: PageProps) {
    return (
        <>
            <SEO
                title='お訪ねになったページが見つかりません。 | Onsen* '
                description='ご指定されたページは移動したか、削除されたかもしれません。 問題があれば、お問い合わせください。'
                robots='noindex,nofollow,noarchive'
                url={location.href}
            />
            <NotFound />
        </>
    )
}
