import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import Typography from '~/components/atoms/Typography'
import LinkButton from '~/components/molecules/LinkButton'

import { btn, container, sm_none, tablet_none, text_description, text_title } from './styles'

const ConceptSection: React.VFC = ({ ...props }) => {
    const { bg_concept } = useStaticQuery<GatsbyTypes.ConceptSectionQuery>(graphql`
        query ConceptSection {
            bg_concept: file(relativePath: {eq: "bg-concept.png"}) {
			    childImageSharp {
			      fluid(maxWidth: 1200) {
			        ...GatsbyImageSharpFluid_withWebp
			      }
			    }
		    }
        }
    `)

    return (
        <BackgroundImage fluid={bg_concept.childImageSharp.fluid}>
            <div css={container} {...props}>
                <Typography variant='h5' color='textSecondary'>Onsen*とは</Typography>
                <Typography css={text_title} variant='h2'
                            color='textPrimary'>入浴剤と専門アプリで<br />すべてが新しいおふろ<br css={tablet_none} />体験。</Typography>
                <Typography css={text_description} variant='body1'>
                    スマートフォンやインターネットで<br />
                    情報に溢れる現代。<br />
                    <br />
                    気づかないうちにストレスがたまり、<br css={sm_none} />いつも何かに刺激されています。<br />
                    <br />
                    必要なのはストレスから解放され<br />
                    自分と向き合う上質な時間。<br />
                    <br />
                    Onsen*は、自分と向き合う<br />
                    あなたを応援します。</Typography>
                <LinkButton css={btn} to='/'>サービスTOPへ</LinkButton>
            </div>
        </BackgroundImage>
    )
}

export default ConceptSection
