import { css } from '@emotion/core'
import { breakpoints } from '~/utils/styles'

export const sm_none = css`
	display: none;
	@media (min-width: ${breakpoints.tablet}px) {
		display: block;
	}
`

export const tablet_none = css`
	display: block;
	@media (min-width: ${breakpoints.tablet}px) {
		display: none;
	}
`

export const container = css`
	padding: 40px 20px;
	background: linear-gradient(180deg, #F0F3F5 0%, rgba(240, 243, 245, 0.503042) 49.7%, rgba(240, 243, 245, 0) 100%);
	@media (min-width: ${breakpoints.tablet}px) {
		padding: 40px 40px;
		text-align: center;
	}
	@media (min-width: ${breakpoints.pc}px) {
		padding: 80px 0;
	}
`

export const text_title = css`
	margin-top: 10px;
	@media (min-width: ${breakpoints.tablet}px) {
		margin-top: 20px;
	}
`

export const text_description = css`
	margin-top: 20px;
	@media (min-width: ${breakpoints.tablet}px) {
		margin-top: 30px;
	}
`

export const btn = css`
	margin-top: 40px;
	@media (min-width: ${breakpoints.tablet}px) {
		width: 216px;
		margin-left: auto;
		margin-right: auto;
	}
`