import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import Typography from '~/components/atoms/Typography'
import CVDualLink from '~/components/molecules/CVDualLink'
import NewsList from '~/components/organisms/NewsList'
import ServiceList from '~/components/organisms/ServiceList'
import ConceptSection from '~/components/organisms/ConceptSection'

import {
    article,
    background_img_hero, bg_primary, concept_container,
    container,
    dual_link, sm_none, tablet_dual_link, tablet_none,
    text_container,
    text_page_description,
    text_page_title
} from './styles'
import { Container } from '~/components/layout/Container'

const NotFoundPage = () => {
    const { notFoundHeroImage } = useStaticQuery<GatsbyTypes.NotFoundPageQuery>(graphql`
		query NotFoundPage {
			notFoundHeroImage: file(relativePath: {eq: "bg-notfound.jpg"}) {
			    childImageSharp {
			      fluid(maxWidth: 1200) {
			        ...GatsbyImageSharpFluid_withWebp
			      }
			    }
		    }
		}
	`)

    return (
        <div css={container}>
            <Container css={article}>
                <BackgroundImage css={background_img_hero} fluid={notFoundHeroImage.childImageSharp.fluid} />
                <div css={text_container}>
                    <Typography css={text_page_title} color='textPrimary'>お訪ねになった<br />ページが見つかり<br css={tablet_none} />ません。</Typography>
                    <Typography css={text_page_description} variant='body1'>ご指定されたページは移動したか、削除されたかもしれません。<br
                        css={sm_none} />問題があれば、お問い合わせください。</Typography>
                    <CVDualLink
                        css={tablet_dual_link}
                        left={{
                            text: '買い物を続ける',
                            link: '/collections/dailymineral'
                        }}
                        right={{
                            text: '問い合わせる',
                            link: 'https://on-sen.zendesk.com/hc/ja/requests/new'
                        }} />
                </div>
            </Container>
            <CVDualLink
                css={dual_link}
                left={{
                    text: '買い物を続ける',
                    link: '/collections/dailymineral'
                }}
                right={{
                    text: '問い合わせる',
                    link: 'https://on-sen.zendesk.com/hc/ja/requests/new'
                }} />

            <NewsList />

            <ConceptSection css={[article, concept_container]} />

            <div css={bg_primary}>
                <ServiceList />
            </div>
        </div>
    )
}

export default NotFoundPage
