import { css } from '@emotion/core'
import { palette } from '~/utils/colors'

export const container = css`
	font-size: 1.4rem;
	padding: 14px 0;
	border-radius: 22px;
	display: block;
	line-height: 1em;
	text-align: center;
	min-height: 44px;
	font-weight: bold;
	width: 100%;
	background-color: ${palette.common.white};
	color: ${palette.primary.main};
	border: 1px solid ${palette.primary.main};
	text-transform: none;

	:hover {
		color: ${palette.common.white};
		background-color: ${palette.secondary.main};
	}
`