import { css } from '@emotion/core'
import { palette } from '~/utils/colors'
import { breakpoints } from '~/utils/styles'

export const sm_none = css`
	display: none;
	@media (min-width: ${breakpoints.tablet}px) {
		display: block;
	}
`

export const tablet_none = css`
	display: block;
	@media (min-width: ${breakpoints.tablet}px) {
		display: none;
	}
`

export const container = css`
	position: relative;
`

export const article = css`
	position: relative;
`

export const background_img_hero = css`
	height: 534px;
	margin-left: 40px;
	padding-top: 40px;
	border-radius: 0 0 0 40px;
	overflow: hidden;
	background-color: rgba(255, 255, 255, 0.8);
	background-blend-mode: lighten;
	@media (min-width: ${breakpoints.tablet}px) {
		margin-right: calc(-1 * calc(100vw / 2 - 100% / 2));
		margin-left: 206px;
		height: 697px;
	}
`

export const text_container = css`
	position: absolute;
	top: 40px;
    padding-left: 20px;
    padding-right: 20px;
	@media (min-width: ${breakpoints.tablet}px) {
		left: 40px;
	}
	@media (min-width: ${breakpoints.pc}px) {
		top: 80px;
		left: 0px;
	}
`

export const text_page_title = css`
	font-size: 3.75rem;
	@media (min-width: ${breakpoints.tablet}px) {
		font-size: 5.6rem;
	}
`

export const text_page_description = css`
	margin-top: 20px;
	@media (min-width: ${breakpoints.tablet}px) {
		margin-top: 40px;
	}
`

export const tablet_dual_link = css`
	display: none;
	@media (min-width: ${breakpoints.tablet}px) {
		display: flex;
		margin-top: 40px;
		width: 384px;
	}
`

export const dual_link = css`
	margin-top: 20px;
	margin-left: 20px;
	margin-right: 20px;
	@media (min-width: ${breakpoints.tablet}px) {
		display: none;
	}
`

export const concept_container = css`
	margin-top: 40px;
	@media (min-width: ${breakpoints.pc}px) {
		margin-top: 80px;
	}
`

export const bg_primary = css`
	background-color: ${palette.primary.main};
`
