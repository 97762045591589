import React from 'react'
import { Link } from 'gatsby'

import { container } from './styles'

type Props = {
    to: string
    children?: React.ReactNode
}

const LinkButton: React.VFC<Props> = ({ to, children, ...props }) => {
    return (
        <Link {...props} css={container} to={to}>
            {children}
        </Link>
    )
}

export default LinkButton