import React from 'react'
import { Link } from 'gatsby'
import Button from '~/components/atoms/Button'

import { btn, container, right_btn, left_btn } from './styles'

type Props = {
    left: {
        text: string
        link: string
    }
    right: {
        text: string
        link: string
    }
}

const CVDualLink: React.VFC<Props> = ({ left, right, ...props }) => {
    return (
        <div css={container} {...props}>
            <Link to={left.link}>
                <Button css={[btn, left_btn]}>{left.text}</Button>
            </Link>
            <a href={right.link} target='_blank' rel='noopener noreferrer'>
                <Button css={[btn, right_btn]}>{right.text}</Button>
            </a>
        </div>
    )
}

export default CVDualLink